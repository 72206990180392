<template>
  <div>
    <main-nav :activeIndex="activeIndex"></main-nav>
    <div class="solution-header-container">
      <div class="solution-header-text">
        <h1 data-caption-delay="0" class="wow fadeInLeft">PREEvision二次开发</h1>
        <p data-caption-delay="100" class="wow fadeInRight">基于PREEvision产品提供的二次开发接口，辅助用户建立模型、架构评估、输出报告等</p>
        <div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)"><div>立即咨询</div></div>
      </div>
    </div>
    <section class="solution-intros wow fadeInLeft">
      <div class="container">
        <div class="normal-title">解决方案介绍</div>
        <div class="all-intro">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div class="intro-text" style="width: 90%">
                <p style="line-height: 2.2em">Vector公司的PREEvision产品是全球应用最为广泛的电子电气系统（E/E）架构设计工具之一，支持从电子电气架构设计到开发的全过程。</p>
                <p style="line-height: 2.2em">东信创智基于Vector公司PREEvision产品提供的二次开发接口，可加速电子电气系统（E/E）架构设计，辅助用户建立模型、架构评估、输出报告等等，实现PREEvision现有功能的灵活组合、扩展，节约开发时间，提高开发效率和准确率，满足不同设计需求下的开发需要。</p>
              </div>
            </el-col>
          
          </el-row>
        </div>
      </div>
      <div class="left-bg">
        <img src="../../../assets/images/index-about-bg-01.png" alt="">
      </div>
      <div class="right-bg">
        <img src="../../../assets/images/index-about-bg-02.png" alt="">
      </div>
    </section>
    <section class="vehicle-solution-sections solution-functions preevision-solution-function wow fadeInRight">
      <div class="container">
        <div class="normal-title">功能特点</div>
        <div
            class="all-function"
            @mouseenter="$refs.mySwiper.swiper.autoplay.stop()"
            @mouseleave="$refs.mySwiper.swiper.autoplay.start()"
        >
          <swiper :options="swiperOption" ref="mySwiper">
            <swiper-slide v-for="(item, index) in functionList" :key="index">
              <div class="card-item">
                <el-card class="solution-function-card" shadow="hover">
                  <div class="card-icon">
                    <img :src=" item.icon " alt="" :style="'width: ' + item.width +';padding-top:' + item.padding">
                  </div>
                  <div class="card-title">
                    <h2>{{ item.title }}</h2>
                  </div>
                  <div class="card-text">
                    <p>{{ item.content }}</p>
                  </div>
                </el-card>
              </div>
            </swiper-slide>
            <!-- 根据情况引入控件 -->
            <!--            <div class="swiper-pagination" slot="pagination"></div>-->
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </section>
    <section class="preevision-solution-strength wow fadeInLeft">
      <div class="container">
        <div class="normal-title">优势亮点</div>
        <div class="solution-strength">
          <div class="strength-content">
            <el-row>
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div class="strength-text">
                  <ol>
                    <li>自2015年开始使用PREEvision工具进行二次开发工作，拥有丰富开发经验</li>
                    <li>自主封装多套基于Metrics的Java API，提高PREEvision二次开发工作效率</li>
                    <li style="width: 97%">核心成员获得全球PREEvison二次开发资格认证，可快速解决二次开发过程中的各类技术难题</li>
                  </ol>
                </div>
              </el-col>
            
            </el-row>
          </div>
        </div>
      </div>
    </section>
    <section class="solution-application wow fadeInRight">
      <div class="container">
        <div class="normal-title">应用领域</div>
        <div class="all-application">
          <div class="application-content">
            <div class="application-icon">
              <img src="../../../assets/images/application-picture.svg" alt="">
            </div>
            <div class="application-text">
              <p>汽车电子电气架构设计开发</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="dyna-product-system-picture wow fadeInLeft">
      <div class="container">
        <div class="normal-title">系统图片</div>
        <div class="all-system-picture">
          <div class="system-picture">
            <img src="../../../assets/images/solution-pv-system-pic.jpg" alt="">
          </div>
        </div>
      </div>
    </section>
    <bzInfo style="margin-top: 0"/>
    <main-footer></main-footer>
  </div>
</template>

<script>
import mainNav from '@/components/MainNav'
import mainFooter from '@/components/MainFooter'
import bzInfo from '@/components/BzInfo'
import functionPic1 from '@/assets/images/solution-preevision-function-icon-1.svg'
import functionPic2 from '@/assets/images/solution-preevision-function-icon-2.svg'
import functionPic3 from '@/assets/images/solution-preevision-function-icon-3.svg'
import functionPic4 from '@/assets/images/solution-preevision-function-icon-4.svg'
export default {
  name: "preevision",
  components: {
    mainNav,
    mainFooter,
    bzInfo
  },
  data(){
    return {
      activeIndex: '2-1',
      functionList: [
        {
          icon: functionPic1,
          width: "45px",
          padding: "5px",
          title: "规则",
          content: '以图像化的方式描述模型类之间的关系，快速匹配查询'
        },
        {
          icon: functionPic2,
          width: "47px",
          padding: "13px",
          title: "算法",
          content: '基于JAVA语言及产品内置JAR包，编辑用户自定义算法'
        },
        {
          icon: functionPic3,
          width: "41px",
          padding: "9px",
          title: "表格",
          content: '根据用户的需求定制和开发表格，可基于表格动态创建、修改、删除构件'
        },
        {
          icon: functionPic4,
          width: "36px",
          padding: "3px",
          title: "报告",
          content: '自定义报告模板一键导出所有设计信息'
        },
      ],
      swiperOption: {
        spaceBetween: 20, // 图片之间的间距
        centeredSlides: false, // 居中还是从图1开始
        slidesPerView: 2, // 一屏幕显示几个? 数字或者默认  auto 自动。
        breakpointsInverse: true,
        breakpoints: {
          //当宽度大于等于1100
          1100: {
            slidesPerView: 4,
            spaceBetween: 10
          }
        },
        notNextTick: true, // true:加载后允许触发事件 false:加载后不可以触发事件
        // loop: true, // 循环吗
        initialSlide: 0, // 从第几个开始
        autoplay: {
          delay: 5000, // 等5秒下一个
          disableOnInteraction: false // 中间滑动一下，取消自动吗？
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }, // 下按钮
        speed: 800, // 滑动时候动画的速度
        paginationClickable: true, // 下面按钮让点吗
        navigation: {
          prevEl: '.swiper-button-prev', // 左侧按钮
          nextEl: '.swiper-button-next' // 右侧按钮
        },
        // effect: 'fade', // 渐入效果
        watchSlidesProgress: true, // 开启这个参数来计算每个slide的progress
        watchSlidesVisibility: true  // 先要开启watchSlidesProgress参数，如果开启watchSlidesVisibility，则会在每个slide增加一个指示该slide的progress值得classname
        // autoHeight: true  // 图片高度自适应
      },
      chatUrl:"https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
    }
  },
  mounted() {
    new this.$wow.WOW().init();
    document.title = "PREEvision二次开发 - 解决方案 - 东信创智";
  },
  methods: {
    linkUrl(url){
      window.open(url,'_blank') // 在新窗口打开外链接
      // window.location.href =this.indexro;  //在本页面打开外部链接
    },
  }
}
</script>
